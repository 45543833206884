
import { defineComponent, onMounted } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import Quill from "quill";

export default defineComponent({
  name: "layout-editor",
  components: {
  
  },
  props: {
    quillEditorId: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      const editorId = props.quillEditorId;
      // init editor
      const options = {
        modules: {
          toolbar: {
            container: "#"+props.quillEditorId+"_toolbar",
          },
        },
        theme: "snow",
      };

      // Init editor
      new Quill("#" + editorId, options);
      //const data = new Quill("#kt_forms_widget_1_editor");

      //console.log(data.root.innerHTML);
    });
    return {
      toolbarWidthFluid,
    };
  },
});

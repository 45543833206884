
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useBus } from '@/bus';

import '@fortawesome/fontawesome-free/css/all.css';
import KTEditor from "@/layout/toolbar/QuillEditor.vue";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import Quill from "quill";

export default defineComponent({
  name: 'kt-drawer-task-upload',
  mixins: [apiEndpoint],
  components: { Field,KTEditor },
  props: {
    data: { type: Object },
  },
  setup() {

    return {

      
    };
  },
  data() {
    return {
      entity: {
        entity_type_id: "",
        email: "",
        telephone: "",
        industry_sector: "",
        web_url: "",
        address: "",
        parent_entity_id: "",
        postcode: "",
        division: "",
        district: "",
        sub_district: "",
        entity_description: "",
        status: "",
        active_status: "1",
        status_remarks: "",
        fax: "",
        name: "",
        entity_short_name: "",
        registration_number: "",
        registration_date: "",
        registration_authority: "",
      },
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
      drawer: {},
      load: false,
      taskDetailData: [] as any,
      files: [] as any,
      inputFiles: [] as any,
      fileNameByUser: {} as any,
      fileName: [] as any,
      fileEvent: [] as any,
      lists: [] as any,
      upazila_id: "" as any,
      upazila_name_eng: "" as any,
      grid_name: "" as any,
      mouza_name: "" as any,
      current_status: "2",
      task_type: "2",
      employee_id: '' as any,
      uploadProgress: 0 as any,
      disableButton: false,
    };
  },

  async created() {
    const { bus } = useBus();
    bus.on('task-data-upload', async (data) => {
      let taskData = data.taskDetail as any;
      this.taskDetailData = taskData;
      console.log(this.taskDetailData);
      this.upazila_name_eng = this.taskDetailData?.task?.upazila_info?.upazila_name_eng;
      this.employee_id = this.taskDetailData?.assigned_to;
      this.grid_name = this.taskDetailData?.task?.grid_info?.grid_name;
      this.mouza_name = this.taskDetailData?.task?.mouza_info?.mouza_name;
    });
  },
  methods: {
    async formSubmit() {
    this.disableButton= true;
    let formData = new FormData();

    for(var key in this.fileNameByUser){
          this.fileName.push(this.fileNameByUser[key]);
    }

    formData.set('task_detail_id', this.taskDetailData?.id);
    formData.set('assigned_to', this.taskDetailData?.assigned_to);
    formData.set('assigned_by', this.taskDetailData?.assigned_by);
    formData.set('created_by', this.taskDetailData?.assigned_to);
    formData.set('updated_by', this.taskDetailData?.assigned_to);
    formData.set('current_status', '2');
    formData.set('employee_id', this.employee_id);
    formData.set('task_type', this.taskType);

    if (this.fileEvent && this.fileEvent.target && this.fileEvent.target.files && this.fileEvent.target.files.length > 0){
        const files= this.fileEvent.target.files;
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
        formData.set('files', this.fileEvent);
        formData.set('fileNameByUser', this.fileName);
      }

      this.loading = true;
      await ApiService.post('configurations/task/file-upload', formData, (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            this.uploadProgress = percentage;
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == 'success') {
              Swal.fire({
                text: 'Save Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-success",
                },
              }).then(() => {
            // window.location.reload();
              this.$router.push('/tasks/draft-tasks');
          });
        }
        else {
          Swal.fire({
            text: 'Something Went Wrong!',
            icon: "warning",
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-danger",
            },
          });
        }

      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
    uploadFile(){
      let button = '' as any;
        button = document.querySelector('.fileInput');
      if (button) {
        button.click();
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.fileEvent= e;
      if (!files.length) return;
      this.inputFiles.push(files);
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        let name = files[index].name;
        let type = name.split('.').pop();
        let size = files[index].size;
        size = size/10485760;
        var reader = new FileReader();
        reader.onload = function(event) {
          if(event.target){
            const imageUrl = event.target.result;
            let data = {
              src:imageUrl,
              name: name,
              type: type,
              size: size,
            };
            vm.files.push(data);
          }
           
        }
        reader.readAsDataURL(files[index]);
      }
      
    },
    removeImage(index) {
      this.files.splice(index, 1)
    },

  },
 
});

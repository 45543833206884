
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useBus } from '@/bus';

export default defineComponent({
  name: 'kt-drawer-received-file-list',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      drawer: {},
      load: false,
      taskDetailData: [] as any,
      fileList: [] as any,
      upazila_name_eng:'' as any,
      grid_name:'' as any,
      files: [] as any,
      feedbackFiles: [] as any,
    };
  },
      
  async created() {
    const { bus } = useBus();
     bus.on('received-data-view', async (data) => {
      let taskData = data as any;
      this.taskDetailData = taskData.taskDetail;
      this.upazila_name_eng = this.taskDetailData?.task?.upazila_info?.upazila_name_eng;
      this.grid_name = this.taskDetailData?.task?.grid_info?.grid_name;
      this.getRecivedTaskFiles(this.taskDetailData.id);
      this.getRecivedFeedbackFiles(this.taskDetailData.id);
    });
   
  },
  methods: {
    async getRecivedTaskFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.files= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async getRecivedFeedbackFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/feedback-file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.feedbackFiles= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async deleteFile(file_id, index)
    {
      Swal.fire({
        title: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          ApiService.delete('configurations/task/file-delete?file_id=' + `${file_id}`)
            .then((response) => {
              Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                this.files.splice(index, 1);
                this.load=false;
              });
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    downloadFile(file_id) {
      window.location.href = ApiService.API_URL+`/file-download/${file_id}`;
    },
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "py-6 content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }
const _hoisted_5 = {
  id: "kt_content_container",
  class: "w-100 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_KTAssociationContract = _resolveComponent("KTAssociationContract")!
  const _component_KTAssociationContractView = _resolveComponent("KTAssociationContractView")!
  const _component_KTDrawerTaskEdit = _resolveComponent("KTDrawerTaskEdit")!
  const _component_KTDrawerTaskUpload = _resolveComponent("KTDrawerTaskUpload")!
  const _component_KTDrawerTaskList = _resolveComponent("KTDrawerTaskList")!
  const _component_KTDrawerTaskAssign = _resolveComponent("KTDrawerTaskAssign")!
  const _component_KTDrawerReceivedFileList = _resolveComponent("KTDrawerReceivedFileList")!
  const _component_KTDrawerTaskAssignToRs = _resolveComponent("KTDrawerTaskAssignToRs")!
  const _component_KTDrawerTaskAssignToCSC = _resolveComponent("KTDrawerTaskAssignToCSC")!
  const _component_KTDrawerTaskAssignToDigitizer = _resolveComponent("KTDrawerTaskAssignToDigitizer")!
  const _component_KTDrawerTaskAssignToSurvey = _resolveComponent("KTDrawerTaskAssignToSurvey")!
  const _component_KTDrawerTaskAssignToAdmin = _resolveComponent("KTDrawerTaskAssignToAdmin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_KTAside, {
        lightLogo: _ctx.themeLightLogo,
        darkLogo: _ctx.themeDarkLogo
      }, null, 8, ["lightLogo", "darkLogo"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_router_view)
            ])
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp),
    _createVNode(_component_KTAssociationContract),
    _createVNode(_component_KTAssociationContractView),
    _createVNode(_component_KTDrawerTaskEdit),
    _createVNode(_component_KTDrawerTaskUpload),
    _createVNode(_component_KTDrawerTaskList),
    _createVNode(_component_KTDrawerTaskAssign),
    _createVNode(_component_KTDrawerReceivedFileList),
    _createVNode(_component_KTDrawerTaskAssignToRs),
    _createVNode(_component_KTDrawerTaskAssignToCSC),
    _createVNode(_component_KTDrawerTaskAssignToDigitizer),
    _createVNode(_component_KTDrawerTaskAssignToSurvey),
    _createVNode(_component_KTDrawerTaskAssignToAdmin)
  ], 64))
}

import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useBus } from '@/bus';

export default defineComponent({
  name: 'kt-drawer-task-assign',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      drawer: {},
      load: false,
      taskDetailData: [] as any,
      fileList: [] as any,
      upazila_name_eng:'' as any,
      grid_name:'' as any,
      files: [] as any,
      forward_assigned_to:'' as any,
      deadline:'' as any,
      employee_id: '' as any,
      task_type: '' as any,
      employeeList: '' as any,
      loading : '' as any,
      feedbackFiles: [] as any,

    };
  },
      
  async created() {
    const { bus } = useBus();
     bus.on('task-data-assign', async (data) => {
      let taskData = data as any;
      this.taskDetailData = taskData.taskDetail;
      this.employeeList = taskData.employeeList;
      console.log(this.taskDetailData);
      this.upazila_name_eng = this.taskDetailData?.task?.upazila_info?.upazila_name_eng;
      this.grid_name = this.taskDetailData?.task?.grid_info?.grid_name;
      this.task_type= this.taskDetailData?.task_type;
      this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
      this.getTaskFiles(this.taskDetailData.id);
      //await this.getRoleEmployee();
      this.getRecivedFeedbackFiles(this.taskDetailData.id);
    });
   
  },
  methods: {

    // async getRoleEmployee() {
    //   await ApiService.get("configurations/employee/roleList?role_id=41")
    //     .then((response) => {
    //       this.employeeList = response.data.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },

    async getTaskFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.files= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async getRecivedFeedbackFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/feedback-file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.feedbackFiles= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async deleteFile(file_id, index)
    {
      Swal.fire({
        title: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          ApiService.delete('configurations/task/file-delete?file_id=' + `${file_id}`)
            .then((response) => {
              Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                this.files.splice(index, 1);
                this.load=false;
              });
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    downloadFile(file_id) {
      window.location.href = ApiService.API_URL+`/file-download/${file_id}`;
    },

    async formSubmit() { 
      let formData = new FormData();
      formData.set('assigned_to', this.forward_assigned_to);
      formData.set('assigned_by', this.employee_id);
      formData.set('deadline', this.deadline);
      formData.set('task_type', this.task_type);
      formData.set('created_by', this.employee_id);
      formData.set('updated_by', this.employee_id);
      formData.set('task_detail_id', this.taskDetailData.id);
      
      this.loading = true;
      await ApiService.post('configurations/task/forward',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            Swal.fire({
                text:  'Save Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-success",
              },
            }).then(() => {
              this.$router.push('/dash');
            });
          }
          else
          {
            Swal.fire({
                text:  'Something Went Wrong!',
                icon: "warning",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-danger",
              },
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
  },
});


import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useBus } from '@/bus';
import KTEditor from "@/layout/toolbar/QuillEditor.vue";


export default defineComponent({
  name: 'kt-drawer-task-assign-to-rs',
  mixins: [apiEndpoint],
  components: { Field, KTEditor },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      quillEditorId: 'task-assign-to-rs' as any,
      drawer: {},
      load: false,
      taskDetailData: [] as any,
      fileList: [] as any,
      upazila_name_eng:'' as any,
      grid_name:'' as any,
      forward_assigned_to:'' as any,
      deadline:'' as any,
      employee_id: '' as any,
      task_type: '' as any,
      employeeList: '' as any,
      loading : '' as any,
      revert_note : '' as any,
      role_id : '' as any,
      previousTaskTypeData : '' as any,
      employeeInfo: {} as any,
      employee_name:'' as any,
      files: [] as any,
      inputFiles: [] as any,
      fileNameByUser: {} as any,
      fileName: [] as any,
      fileEvent: [] as any,
      uploadProgress: 0 as any,
      disableButton: false,
      is_feedback_file: 1,
    };
  },
      
  async created() {
    const { bus } = useBus();
     bus.on('task-data-assign', async (data) => {
      let taskData = data as any;
      this.taskDetailData = taskData.taskDetail;
      this.upazila_name_eng = this.taskDetailData?.task?.upazila_info?.upazila_name_eng;
      this.grid_name = this.taskDetailData?.task?.grid_info?.grid_name;
      this.task_type= this.taskDetailData?.task_type;
      this.employee_id= VueCookieNext.getCookie("_seip_employee_id");  
      this.role_id= VueCookieNext.getCookie("_seip_role_id");
      this.getPreviousTaskType(this.taskDetailData.id);
      this.getTaskFiles(this.taskDetailData.id);
    });
    
    // await this.getRoleEmployee();
  },
  methods: {

    // async getRoleEmployee() {
    //   await ApiService.get("configurations/employee/roleList?role_id="+ this.role_id)
    //     .then((response) => {
    //       this.employeeList = response.data.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async getPreviousTaskType(task_detail_id) {
      await ApiService.get("configurations/task/previous_task_type?task_detail_id="+task_detail_id + "&role_id="+ this.role_id)
        .then((response) => {
          
          this.previousTaskTypeData = response.data.data;
          console.log(this.previousTaskTypeData);
          this.forward_assigned_to= this.previousTaskTypeData?.assigned_to;
          const receiver_emp_id= this.previousTaskTypeData?.assigned_to;
          this.getEmployee(receiver_emp_id);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    
    async getEmployee(emp_id) {
      await ApiService.get("configurations/employee/getEmployee?id="+ emp_id)
        .then((response) => {
          this.employeeInfo = response.data.data;
          console.log(this.employeeInfo);
          this.employee_name= this.employeeInfo.name;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getTaskFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.files= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async deleteFile(file_id, index)
    {
      Swal.fire({
        title: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          ApiService.delete('configurations/task/file-delete?file_id=' + `${file_id}`)
            .then((response) => {
              Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                this.files.splice(index, 1);
                this.load=false;
              });
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    downloadFile(file_id) {
      window.location.href = ApiService.API_URL+`/file-download/${file_id}`;
    },

    async formSubmit() { 
      let editorElement = '' as any;
      editorElement = document.querySelector('#'+ this.quillEditorId +' .ql-editor');
      const revert_note = editorElement.innerHTML;
      this.disableButton= true;
      let formData = new FormData();
      for(var key in this.fileNameByUser){
          this.fileName.push(this.fileNameByUser[key]);
    }
      formData.set('assigned_to', this.forward_assigned_to);
      formData.set('assigned_by', this.employee_id);
      formData.set('reverted_by', this.employee_id);
      formData.set('revert_note', revert_note);
      formData.set('deadline', this.deadline);
      formData.set('task_type', this.task_type);
      formData.set('role_id', this.role_id);
      formData.set('created_by', this.employee_id);
      formData.set('updated_by', this.employee_id);
      formData.set('task_detail_id', this.taskDetailData.id);

      if (this.fileEvent && this.fileEvent.target && this.fileEvent.target.files && this.fileEvent.target.files.length > 0){
        const files= this.fileEvent.target.files;
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
        formData.set('files', this.fileEvent);
        formData.set('fileNameByUser', this.fileName);
      }
      
      this.loading = true;
      await ApiService.post('configurations/task/revert',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            Swal.fire({
                text:  'Save Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-success",
              },
            }).then(() => {
              this.$router.push('/dash');
            });
          }
          else
          {
            Swal.fire({
                text:  'Something Went Wrong!',
                icon: "warning",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-danger",
              },
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    uploadFeedbackFile(){
      let button = '' as any;
        button = document.querySelector('.feedbackFileInput');
      if (button) {
        button.click();
      }
    },
    onFeedbackFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.fileEvent= e;
      if (!files.length) return;
      this.inputFiles.push(files);
      this.createFeedbackImage(files);
    },
    createFeedbackImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        let name = files[index].name;
        let type = name.split('.').pop();
        let size = files[index].size;
        size = size/10485760;
        var reader = new FileReader();
        reader.onload = function(event) {
          if(event.target){
            const imageUrl = event.target.result;
            let data = {
              src:imageUrl,
              name: name,
              type: type,
              size: size,
            };
            vm.files.push(data);
          }
           
        }
        reader.readAsDataURL(files[index]);
      }
      
    },
    removeImage(index) {
      this.files.splice(index, 1)
    },
  },
});

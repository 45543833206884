
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
//import AdminMenuConfig from "./Menu";
import { useStore } from "vuex";
import DashBorad from "./Menu";
import store from "@/store";
import { VueCookieNext } from "vue-cookie-next";
import {apiEndpoint} from "@/mixin/apiMixin.js"

export default defineComponent({
  mixins:[apiEndpoint],
  name: "kt-menu",
  components: {},
  data() {
    return {
      role_id: "" as any,
      menuConfig: [] as any,
      AdminMenuConfig: [
        {
          heading: "menu",
          route: "/crafted",
          pages: [
            {
              heading: "dashboard",
              route: "/dash",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
            },
          ],
        },
      ],
      superAdminMenuConfig: [
        {
          pages: [
            {
              heading: "Waiting Approval Tasks",
              route: "/tasks/waiting-approval-tasks",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
            },
            {
              heading: "Approval Tasks",
              route: "/tasks/approval-tasks",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
            },
          ],
        },
      ],
      GisMenuConfig: [
        {
          pages: [
          {
              heading: "All Task",
              route: "/gis-user/all-task",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Create & Assign Task",
              route: "/gis-user/create-task",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Assign QC (RS File)",
              route: "/gis-user/assign-qc-rs-file",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Assign Digitizer (QC File)",
              route: "/gis-user/assign-digitizer",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Assign QC (Digitized File)",
              route: "/gis-user/assign-qc-digitized-file",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Assign Survey (Image File)",
              route: "/gis-user/assign-survey",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "Assign QC (Survey File)",
              route: "/gis-user/assign-qc-survey-file",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          {
              heading: "QC Approve File",
              route: "/gis-user/user-wise-complete-tasks",
              fontIcon: "bi-dashboard",
              svgIcon: "media/icons/duotune/general/gen025.svg",
          },
          ],
        },
      ],
    };
  },
  async created() {
    //await this.Dashboard();
    this.role_id = VueCookieNext.getCookie('_seip_role_id');
    //console.log(this.role_id);
    await this.getMenus();
  },

  // async mounted()
  // {
  //   await this.getMenus();
  // },

  methods: {
    async getMenus() {
      const store = useStore();
      this.role_id = VueCookieNext.getCookie('_seip_role_id');
      ApiService.get(
        'menuactionrole/permitted_action_list?entity_type_role_id=' +
        this.role_id + '&menu_id=1'
      )
        .then((response) => {
          this.menuConfig.pages= response.data.data;
          this.menuConfig.heading= '';
          console.log(this.menuConfig);
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});

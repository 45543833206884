
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useBus } from '@/bus';

import '@fortawesome/fontawesome-free/css/all.css';
import KTEditor from "@/layouts/main-layout/toolbar/QuillEditor.vue";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import Quill from "quill";

export default defineComponent({
  name: 'kt-drawer-task-edit',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },
  setup() {

    return {

      
    };
  },
  data() {
    return {
      entity: {
        entity_type_id: "",
        email: "",
        telephone: "",
        industry_sector: "",
        web_url: "",
        address: "",
        parent_entity_id: "",
        postcode: "",
        division: "",
        district: "",
        sub_district: "",
        entity_description: "",
        status: "",
        active_status: "1",
        status_remarks: "",
        fax: "",
        name: "",
        entity_short_name: "",
        registration_number: "",
        registration_date: "",
        registration_authority: "",
      },
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
      drawer: {},
      load: false,
      taskDetailData: [] as any,
      newfiles: [] as any,
      inputFiles: [] as any,
      newFileNameByUserEdit: {} as any,
      newFileName: [] as any,
      fileEvent: [] as any,
      lists: [] as any,
      upazila_id: "" as any,
      upazila_name_eng: "" as any,
      grid_name: "" as any,
      mouza_name: "" as any,
      fileList: [] as any,
      files: [] as any,
      confirmloading:false,
      employee_id:'' as any,
      taskType:'' as any,
      uploadProgress: 0,
      disableBtn: false,
      feedbackFiles: [] as any,

    };
  },

  async created() {
    const { bus } = useBus();
    bus.on('task-data-edit', async (data) => {
      let taskData = data as any;
      this.taskDetailData = taskData.taskDetail;
      this.upazila_name_eng = this.taskDetailData?.task?.upazila_info?.upazila_name_eng;
      this.grid_name = this.taskDetailData?.task?.grid_info?.grid_name;
      this.mouza_name = this.taskDetailData?.task?.mouza_info?.mouza_name;
      this.employee_id = this.taskDetailData?.assigned_to;
      this.taskType = this.taskDetailData?.task_type;
      this.getTaskFiles(this.taskDetailData.id);
      this.getRecivedFeedbackFiles(this.taskDetailData.id);
    });
  },
  methods: {

    async getTaskFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.files= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },
    async getRecivedFeedbackFiles(task_detail_id)
    {
      this.load=true;
      await ApiService.get("configurations/task/feedback-file-list?task_detail_id="+task_detail_id)
          .then((response) => {
            this.fileList = response.data.data;           
            this.feedbackFiles= this.fileList;
            this.load=false
          })
          .catch(({ response }) => {
            console.log(response);
            this.load=false
          });
    },

    async deleteFile(file_id, index)
    {
      Swal.fire({
        title: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          ApiService.delete('configurations/task/file-delete?file_id=' + `${file_id}`)
            .then((response) => {
              Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                this.files.splice(index, 1);
                this.load=false;
              });
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    downloadFile(file_id) {
      window.location.href = ApiService.API_URL+`/file-download/${file_id}`;
    },

    async formSubmit(confirmation_status) {
    this.disableBtn= true;
    let formData = new FormData();

    for(var key in this.newFileNameByUserEdit){
          this.newFileName.push(this.newFileNameByUserEdit[key]);
      }

    formData.set('task_detail_id', this.taskDetailData?.id);
    formData.set('current_status', confirmation_status);
    formData.set('employee_id', this.employee_id);
    formData.set('task_type', this.taskType);
    
    if (this.fileEvent && this.fileEvent.target && this.fileEvent.target.files && this.fileEvent.target.files.length > 0){
        const files= this.fileEvent.target.files;
        for (let i = 0; i < files.length; i++) {
          formData.append('newFiles[]', files[i]);
        }
        formData.set('newFiles', this.fileEvent);
        formData.set('newFileNameByUser', this.newFileName);
      }

    if(confirmation_status==2)
    {
      this.loading = true;
    }
    else if(confirmation_status==3)
    {
      this.confirmloading = true;
    }
    
    await ApiService.post('configurations/task/file-update', formData, (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            this.uploadProgress = percentage;
      })
      .then((response) => {
        this.loading = false;
        if (response.data.status == 'success') {
          Swal.fire({
            text: 'Save Successfully.',
            icon: "success",
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-success",
            },
          }).then(() => {
            this.confirmloading = false;
            this.loading = false;
            if(confirmation_status==2)
            {
              window.location.reload();
            }
            else if(confirmation_status==3)
            {
              this.$router.push('/tasks/completed-tasks');
            }
      });
        }
        else {
          Swal.fire({
            text: 'Something Went Wrong!',
            icon: "warning",
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-danger",
            },
          });
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    uploadEditFile(){
      let button = '' as any;
        button = document.querySelector('.fileEditInput');
      if (button) {
        button.click();
      }
    },
    onEditFileChange(e) {
      console.log(e); 
      var files = e.target.files || e.dataTransfer.files;
      this.fileEvent= e;
      if (!files.length) return;
      this.inputFiles.push(files);
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        let name = files[index].name;
        let type = name.split('.').pop();
        let size = files[index].size;
        size = size/10485760;
        var reader = new FileReader();
        reader.onload = function(event) {
          if(event.target){
            const fileUrl = event.target.result;
            let data = {
              src:fileUrl,
              name: name,
              type: type,
              size: size,
            };
            vm.newfiles.push(data);
          }
           
        }
        reader.readAsDataURL(files[index]);
      }
      
    },
    removeImage(index) {
      this.newfiles.splice(index, 1)
    },

  },
 
});
